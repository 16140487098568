import React from "react-router-dom";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom";
import { ethers } from 'ethers'

import './IMXOnSale.scss'
import CustomButton from "../../../generics/CustomButton/CustomButton";

export default function IMXOnSale({
                                    key,
                                    item: {order_id, buy, sell },
                                    showSellModal = null,
                                    showTransferModal = null,
                                    processing = false,
                                    userOwner = false,
                                    hideButtons = false
                                }) {
    const history = useHistory();

    return (
        <LazyLoad key={key} height={'400px'} once>
            <div className={'imx-item-wrapper'}>
                <div className={'imx-image-wrapper'}>
                    <img src={sell.data.properties?.image_url} alt=""/>
                </div>
                <div className={ `item-description ${hideButtons ? 'block-center' : ''}` }>
                    <p className={'collection'}>{ sell.data.properties?.collection.name }</p>
                    <p>{sell.data.properties?.name}</p>

                    { !hideButtons && buy && !!!showSellModal &&
                        <div className={'item-action'}>
                            <CustomButton
                                text={ userOwner ? 'Cancel' : 'Buy' }
                                onClick={ () => history.push(`/imx-market/${order_id}`) }
                                disabled={processing}
                            />

                            <div className={'item-price'}>
                                <p>Price:</p>
                                <p>{ethers.utils.formatEther(buy.data.quantity)} ETH</p>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </LazyLoad>
    )
}