import {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";

import { Accordion, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import FlowIcon from "../../../resources/images/blockchain/flow_icon.png";
import ImxIcon from "../../../resources/images/blockchain/immutable_x.png";

import './BlockchainFilter.scss'


function BlockchainFilter({blockchainSelected, setBlockchainSelected}) {
    const history = useHistory();
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(history.location.search);
        const blockchain = queryParams.get("blockchain");
        if (blockchain) {
            setBlockchainSelected(blockchain);
        }
    }, []);


    const blockchains = [
        {id: 1, name: 'Flow', img: FlowIcon, click: () => {handleSelectedBlockchain('Flow')}},
        {id: 1, name: 'ImmutableX', img: ImxIcon, click: () => {handleSelectedBlockchain('Immutable')}}
    ]

    const handleSelectedBlockchain = (blockchain) => {
        history.push(`?blockchain=${blockchain}`);
        setBlockchainSelected(blockchain)
        setExpanded(false)
    }

    return (
        <div className={'blockchain-filter-wrapper'}>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary
                    expandIcon={ <ExpandMoreIcon /> }
                >
                    <img src={ blockchainSelected === 'Flow' ? FlowIcon : ImxIcon } alt="" />
                    <Typography>{blockchainSelected} blockchain</Typography>
                </AccordionSummary>
                <div className="blockchain-filter_container">
                    <h4>Blockchains:</h4>
                    <div className="blockchain-list">
                        {blockchains.map((item) =>
                            <div className="blockchain-item" onClick={item.click}>
                                <img src={item.img} alt=""/>
                                <p>{item.name}</p>
                            </div>
                        )}
                    </div>
                </div>
            </Accordion>
        </div>
    );
}

export default BlockchainFilter